import type { FC } from 'react';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import {
  NoReceiptsAdded,
  ReceiptBatchDetailsReceipts,
} from './ReceiptBatchDetailsReceipts';
import type { BatchReceiptDetails } from './ReceiptBatchForm';

import { ReceiptBatchImagePreview } from './ReceiptBatchImagePreview';
import { TestIds } from '../testIds';
import type { UseReceiptImagesType } from '@pflegenavi/shared-frontend/platform';
import { EnlargeImageGallery } from '@pflegenavi/transaction-components';
import type { UrlTypeNameMapping } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

interface ReceiptBatchReadOnlyPageProps {
  isLoading: boolean;
  receipts: BatchReceiptDetails[];
  autoGenerateReceipts?: boolean;
  autoGenerateReceiptsPreview?: (entryId: string) => Promise<Blob | undefined>;
  useReceiptImages: UseReceiptImagesType;
  ReceiptBatchSummaryCard: React.ReactElement;
  CashLink?: React.ReactElement;
  ReceiptBatchPaymentCard?: React.ReactElement;
  width?: number | string;
  tooltipForInactiveResidentMessage?: string;
}

export const ReceiptBatchDetails: FC<ReceiptBatchReadOnlyPageProps> = ({
  isLoading,
  receipts,
  autoGenerateReceipts,
  autoGenerateReceiptsPreview,
  useReceiptImages,
  ReceiptBatchSummaryCard,
  CashLink,
  width,
  tooltipForInactiveResidentMessage,
  ReceiptBatchPaymentCard,
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const activeReceiptImageIds = useMemo(() => {
    return receipts[activeIndex]?.receiptImageIds?.filter((id) => !!id);
  }, [activeIndex, receipts]);

  const [generatedReceiptImageUrls, setGeneratedReceiptImageUrls] = useState<
    string[]
  >([]);
  const [generatedReceiptTypeMap, setGeneratedReceiptTypeMap] =
    useState<UrlTypeNameMapping>(new Map());

  const generateReceiptPreviewCallback = useCallback(async () => {
    const receipt = receipts[activeIndex];
    if (receipt && autoGenerateReceiptsPreview) {
      const blob = await autoGenerateReceiptsPreview(receipt.id!);
      if (blob) {
        const file = window.URL.createObjectURL(blob);

        setGeneratedReceiptImageUrls([file]);
        setGeneratedReceiptTypeMap(
          new Map([
            [
              file,
              {
                type: 'application/pdf',
                data: Promise.resolve(blob),
                name: `${t('receipts.batch.receipt-preview-file')}-${
                  receipt.residentName
                }.pdf`,
              },
            ],
          ])
        );
      }
    }
  }, [autoGenerateReceiptsPreview, activeIndex, receipts, t]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={2}
      data-testid={TestIds.ReceiptBatchDetail}
      id="receiptBatchdetail"
    >
      {receipts.length === 0 && !isLoading ? (
        <NoReceiptsAdded />
      ) : (
        <>
          <ReceiptBatchDetailsReceipts
            receipts={receipts}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            tooltipForInactiveResidentMessage={
              tooltipForInactiveResidentMessage
            }
            autoGenerateReceipts={autoGenerateReceipts ?? false}
          />
          <ReceiptBatchImagePreview
            receiptImageIds={activeReceiptImageIds}
            useReceiptImages={useReceiptImages}
            autoGenerateReceipts={autoGenerateReceipts}
            autoGenerateReceiptsPreview={
              autoGenerateReceiptsPreview
                ? generateReceiptPreviewCallback
                : undefined
            }
          />
          <EnlargeImageGallery
            imageUrls={generatedReceiptImageUrls}
            handleClose={() => {
              setGeneratedReceiptImageUrls([]);
            }}
            open={generatedReceiptImageUrls.length > 0}
            initialSelectedIndex={0}
            imageUrlToTypeMap={generatedReceiptTypeMap}
          />
        </>
      )}

      <Stack gap={2} width={width}>
        {ReceiptBatchSummaryCard}

        {CashLink}

        {ReceiptBatchPaymentCard}
      </Stack>
    </Stack>
  );
};
